import {hasSuccessfulAttempt, isStopPickup} from '@getcircuit/schema/dist'
import useDeliveryState from './useDeliveryState'

export function useAttachmentsDefaultMessage() {
  const stop = useDeliveryState()
  const success = hasSuccessfulAttempt(stop?.deliveryInfo)

  if (isStopPickup(stop)) {
    return success
      ? 'Your order was picked up here.'
      : 'Your order could not be picked up here.'
  } else {
    return success
      ? 'Your delivery was left here.'
      : 'Your delivery could not be left here.'
  }
}
