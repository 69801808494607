import React from 'react'
import styled from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'
import {colors, statusColors} from '../../shared/theme'

export const Textarea = styled(({hasError, isFocused, icon, ...rest}) => (
  <TextareaAutosize {...rest} />
))`
  background-color: white;
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
  border-radius: 6px;
  box-sizing: border-box;
  color: ${colors.dark['80']};
  font-size: 18px;
  line-height: 24px;
  max-height: 152px;
  padding: 16px;
  resize: none;
  width: 100%;

  ${({hasError}) => hasError && `border-color: ${colors.red['50']};`}

  ${({isFocused}) =>
    isFocused &&
    `
    border-color: ${colors.blue['50']};
  `}

  ${({icon}) =>
    icon &&
    `
      background-image: url(/icons/${icon}.svg);
      background-position: 16px 15px;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      padding-left: 54px;
    `}

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${colors.dark['50']};
  }
`

export const Counter = styled.div`
  color: ${props => statusColors[props.status] || colors.dark['40']};
  font-size: 16px;
  text-align: right;
  width: 100%;
  height: ${props => (props.visible ? '15px' : '0')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: height 200ms ease-in-out, opacity 130ms 70ms ease-in-out;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`
