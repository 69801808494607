import styled from 'styled-components'
import {colors, mobileMaxWidth} from '../../../shared/theme'

export const Container = styled.div`
  background-color: ${colors.dark['05']};
  cursor: zoom-out;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 5;

  &.is-visible {
    opacity: 1;
    visibility: visible;
  }
`

export const Photo = styled.img`
  height: 10%;
  left: 20px;
  object-fit: contain;
  position: absolute;
  top: 20%;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 10%;

  &.is-visible {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }

  @media ${mobileMaxWidth} {
    height: 20%;
    top: 50%;
    left: 40%;
    width: 20%;
  }
`

export const Button = styled.button`
  background-color: ${colors.dark['30']};
  border-radius: 50%;
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 33px;
  line-height: 0;
  position: absolute;
  right: 30px;
  text-indent: -1000em;
  top: 30px;
  width: 33px;
  z-index: 8;

  &:hover {
    background-color: ${colors.dark['40']};
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: ${colors.dark['30']};
  }

  &:before,
  :after {
    background-color: ${colors.dark['50']};
    content: ' ';
    height: 15pt;
    left: 15px;
    position: absolute;
    top: 6px;
    width: 3px;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @media ${mobileMaxWidth} {
    right: 20px;
    top: 20px;
  }
`
