import styled from 'styled-components'
import {colors, mobileMaxWidth} from '../../../shared/theme'

export const Container = styled.div`
  flex-direction: column;
  width: 100%;
  margin-bottom: 4px;
`

export const SubmitContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => (props.visible ? '12px' : '0')};
  margin-bottom: ${props => (props.visible ? '8px' : '0')};
  height: ${props => (props.visible ? '44px' : '0')};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: height 200ms ease-in-out, opacity 130ms 70ms ease-in-out;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};

  > * {
    margin-left: 14px;
  }

  @media ${mobileMaxWidth} {
    margin-bottom: 24px;
  }
`

export const Cancel = styled.button`
  background: transparent;
  border: none;
  color: ${colors.dark['50']};
  font-size: 16px;
  font-weight: 600;
  padding: 12px 10px 14px;
`

export const Submit = styled.button`
  background-color: ${colors.blue['50']};
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px 14px;
`
