import React from 'react'
import {Container, Logo} from './Loading.styled'

const Loading = () => (
  <Container>
    <Logo src="/animated-logo.svg" alt="Loading delivery info" />
  </Container>
)

export default Loading
