import React from 'react'
import {Container} from './PoweredBy.styled'
import {useDeliveryState} from '../../hooks'

const PoweredBy = () => {
  const {installURL} = useDeliveryState()

  return (
    <Container
      id="powered-by"
      href={installURL || 'https://www.getcircuit.com'}
    >
      <div>
        Powered by
        <img src="circuit-logo.svg" alt="Circuit Logo" />
      </div>
    </Container>
  )
}

export default PoweredBy
