import styled from 'styled-components'
import {colors} from '../../shared/theme'
import {Container} from '../shared/Box/Box.styled'

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 0;
  width: 100%;
`

const Label = styled.span`
  color: ${colors.dark['80']};
  font-size: 18px;
  line-height: 1.5em;
`

const SecondaryLabel = styled.span`
  color: ${colors.dark['50']};
  font-size: 18px;
`

export {Container, LabelContainer, Label, SecondaryLabel}
