import React, {useEffect, useReducer, useState} from 'react'
import appReducer from './reducers'
import {StateContext} from './contexts'
import AppContainer from './App.styled'
import Errors from './Errors'
import Controls from './components/Controls/Controls'
import Map from './components/Map/Map'
import PoweredBy from './components/PoweredBy/PoweredBy'
import Loading from './components/Loading/Loading'
import {fetchDelivery} from './api'

const App = ({token}) => {
  const [error, setError] = useState()
  const [state, dispatch] = useReducer(appReducer, {})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchDelivery(token)
      .then(({data}) => {
        const {notes, ...delivery} = data
        dispatch({
          type: 'FETCH_DELIVERY',
          // Adding `type: stop` so `isStopPickup` function works properly
          delivery: {...delivery, type: 'stop'},
        })
        dispatch({type: 'UPDATE_NOTE', notes})
      })
      .catch(error => {
        setError(
          error.response || {
            status: 500,
            statusText:
              'Apologies. We have an error on our side. Our team has been notified and will solve the issue shortly.',
          },
        )
      })
      .then(() => {
        setLoading(false)
      })
  }, [token, dispatch])

  if (error) {
    return <Errors status={error.status} message={error.statusText} />
  }

  if (loading) {
    return <Loading />
  }

  //'rvh' units are part of react-div-100vh lib
  // for mobile responsiveness
  return (
    <StateContext.Provider value={{state, dispatch}}>
      <AppContainer style={{minHeight: '100rvh'}}>
        <Map />
        <Controls token={token} />
        <PoweredBy />
      </AppContainer>
    </StateContext.Provider>
  )
}

export default App
