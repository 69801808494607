import React from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import './index.css'
import App from './App'
import Errors from './Errors'
import * as serviceWorker from './serviceWorker'

import {FontStyles} from './shared/theme'

Bugsnag.start({
  apiKey: '86a2305392c5764d7b5e037d83672f65',
  plugins: [new BugsnagPluginReact(React)],
})

const ErrorBoundary = Bugsnag.getPlugin('react')

const [, token] = window.location.pathname.split('/')

const pageToRender = token ? (
  <App token={token} />
) : (
  <Errors
    status={400}
    message={'An error has occurred. Please check the url.'}
  />
)

ReactDOM.render(
  <>
    <FontStyles />
    <ErrorBoundary>{pageToRender}</ErrorBoundary>
  </>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
