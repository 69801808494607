function deliveryReducer(state, action) {
  switch (action.type) {
    case 'FETCH_DELIVERY':
      return action.delivery
    default:
      return state
  }
}

export default function appReducer(state, action) {
  return {
    delivery: deliveryReducer(state.delivery, action),
  }
}
