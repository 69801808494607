import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useInput} from 'react-hookedup'
import {Counter, Textarea} from './TextareaBox.styled'

const TextareaBox = ({
  doHideCounter = false,
  handleBlur = () => {},
  handleChange = () => {},
  handleFocus = () => {},
  handleResize = () => {},
  hasError,
  icon,
  iconAlt,
  initialValue,
  maxChars,
  placeholder,
}) => {
  const textarea = useInput(initialValue)

  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    handleChange(textarea.value)
  }, [textarea, handleChange])

  const onChange = e => {
    if (!maxChars || e.target.value.length <= maxChars) {
      textarea.onChange(e)
    }
  }

  const onFocus = () => {
    setIsFocused(true)
    handleFocus()
  }

  const onBlur = () => {
    setIsFocused(false)
    handleBlur()
  }

  const onResize = () => {
    handleResize()
  }

  const getCounterStatus = () => {
    const charsInDanger = 30
    const textLength = textarea.value?.length

    if (textLength === maxChars) {
      return 'danger'
    }
    if (maxChars - textLength <= charsInDanger) {
      return 'alert'
    }
    return 'normal'
  }

  return (
    <>
      <Textarea
        isFocused={isFocused}
        hasError={hasError}
        async={true}
        icon={icon}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onResize={onResize}
        placeholder={placeholder}
        value={textarea.value || ''}
      />
      <Counter
        status={getCounterStatus()}
        visible={!doHideCounter && isFocused}
      >
        {textarea.value?.length || 0}/{maxChars}
      </Counter>
    </>
  )
}

TextareaBox.propTypes = {
  doHideCounter: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleFocus: PropTypes.func,
  handleResize: PropTypes.func,
  hasError: PropTypes.bool,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  initialValue: PropTypes.string,
  maxChars: PropTypes.number,
  placeholder: PropTypes.string,
}

export default TextareaBox
