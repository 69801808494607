import {createGlobalStyle} from 'styled-components'

export const colors = {
  black: 'rgb(0, 0, 0)',
  blue: {
    '05': 'rgb(245, 248, 254)',
    '10': 'rgb(238, 244, 254)',
    '20': 'rgb(207, 222, 253)',
    '30': 'rgb(173, 205, 252)',
    '40': 'rgb(93, 158, 255)',
    '50': 'rgb(24, 116, 255)',
    '80': 'rgb(0, 31, 77)',
  },
  dark: {
    '05': 'rgb(242, 244, 248)',
    '10': 'rgb(220, 226, 238)',
    '30': 'rgb(204, 210, 227)',
    '40': 'rgb(154, 161, 183)',
    '50': 'rgb(81, 87, 103)',
    '80': 'rgb(30, 32, 34)',
  },
  bodyBackground: 'rgb(221, 221, 221)',
  green: {
    '05': 'rgb(247, 252, 248)',
    '30': 'rgb(200, 237, 206)',
    '40': 'rgb(144, 220, 156)',
    '50': 'rgb(96, 204, 113)',
    '80': 'rgb(19, 58, 25)',
  },
  red: {
    '05': 'rgb(254, 245, 245)',
    '30': 'rgb(250, 185, 189)',
    '40': 'rgb(244, 115, 123)',
    '50': 'rgb(239, 55, 66)',
    '80': 'rgb(71, 6, 9)',
  },
  white: 'rgb(255, 255, 255)',
  yellow: {
    '05': 'rgb(255, 251, 245)',
    '30': 'rgb(255, 232, 186)',
    '40': 'rgb(255, 209, 117)',
    '50': 'rgb(255, 188, 57)',
    '80': 'rgb(77, 50, 0)',
  },
}

export const transparencies = {
  dark: {
    '80': {
      '50': 'rgba(30, 32, 34, .5)',
      '40': 'rgba(30, 32, 34, .4)',
    },
  },
}

export const statusColors = {
  active: colors.blue['50'],
  alert: 'rgb(239, 147, 55)',
  danger: colors.red['50'],
}

export const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'TT Commons';
    font-weight: 400;
    font-display: block;
    src: url('/fonts/tt_commons_regular.otf') format('opentype'),
         url('/fonts/tt_commons_regular-webfont.woff2') format('woff2');
  }
  @font-face {
    font-family: 'TT Commons';
    font-weight: 500;
    font-display: block;
    src: url('/fonts/tt_commons_medium.otf') format('opentype'),
         url('/fonts/tt_commons_medium-webfont.woff2') format('woff2');
  }
  @font-face {
    font-family: 'TT Commons';
    font-weight: 600;
    font-display: block;
    src: url('/fonts/tt_commons_demibold.otf') format('opentype'),
         url('/fonts/tt_commons_demibold-webfont.woff2') format('woff2');
  }
`

export const fontFamily = "'TT Commons', sans-serif"

export const mobileMaxWidth = '(max-width: 768px)'
