import {hasFailedAttempt, isStopPickup} from '@getcircuit/schema/dist'
import useDeliveryState from './useDeliveryState'

// Implementation based on
// https://github.com/getcircuit/teams-web-react/blob/main/src/hooks/useGetDeliveryStateLocalized.ts

const FAILED_PICKUP_STATE_LABEL_MAP = {
  failed_not_home: {
    id: "Your pickup failed as recipient wasn't home",
  },
  failed_cant_find_address: {
    id: "Your pickup failed as driver couldn't find the address",
  },
  failed_no_parking: {
    id: "Your pickup failed as driver couldn't find parking",
  },
  failed_no_time: {
    id: "Your pickup failed as driver didn't have time to complete it",
  },
  failed_other: {
    id: 'Your pickup failed (other)',
  },
  failed_package_not_available: {
    id: 'Your pickup goods were not available',
  },
}

const FAILED_DELIVERY_STATE_LABEL_MAP = {
  failed_not_home: {
    id: "Your delivery failed as recipient wasn't home",
  },
  failed_cant_find_address: {
    id: "Your delivery failed as driver couldn't find the address",
  },
  failed_no_parking: {
    id: "Your delivery failed as driver couldn't find parking",
  },
  failed_no_time: {
    id: "Your delivery failed as driver didn't have time to complete it",
  },
  failed_other: {
    id: 'Your delivery failed (other)',
  },
  failed_package_not_available: {
    id: 'Your delivery goods were not available',
  },
}

const DELIVERED_STATE_LABEL_MAP = {
  unattempted: {
    id: '',
  },
  delivered_to_mailbox: {
    id: 'Your order was delivered to mailbox',
  },
  delivered_to_recipient: {
    id: 'Your order was delivered to recipient',
  },
  delivered_to_third_party: {
    id: 'Your order was delivered to third party',
  },
  delivered_to_safe_place: {
    id: 'Your order was left in safe place',
  },
  delivered_other: {
    id: 'Your order was delivered (other)',
  },
  picked_up_from_customer: {
    id: 'Your order was picked up from customer',
  },
  picked_up_unmanned: {
    id: 'Your order was picked up unmanned',
  },
  picked_up_from_locker: {
    id: 'Your order was picked up from locker',
  },
  picked_up_other: {
    id: 'Your order was picked up (Other)',
  },
}

function getFailedAttempt(attemptStop, deliveryState) {
  return isStopPickup(attemptStop)
    ? FAILED_PICKUP_STATE_LABEL_MAP[deliveryState].id
    : FAILED_DELIVERY_STATE_LABEL_MAP[deliveryState].id
}

export function useGetDeliveryStateLocalized() {
  const stop = useDeliveryState()
  const {state: deliveryState} = stop?.deliveryInfo

  if (deliveryState == null || stop == null) {
    return DELIVERED_STATE_LABEL_MAP.delivered_other.id
  }

  const failedAttempt = hasFailedAttempt(stop?.deliveryInfo)

  const failedStateLabel = failedAttempt
    ? getFailedAttempt(stop, deliveryState)
    : null

  const stateLabelId = failedAttempt
    ? failedStateLabel
    : DELIVERED_STATE_LABEL_MAP[deliveryState]?.id ??
      DELIVERED_STATE_LABEL_MAP.delivered_other.id

  return stateLabelId
}
