import React from 'react'
import {useDeliveryState} from '../../hooks'
import GoogleMapReact from 'google-map-react'
import './Map.css'

import {Marker, MapStyle} from './Map.styled'

const Map = () => {
  const delivery = useDeliveryState()
  const {latitude, longitude} = delivery

  if (!(latitude && longitude)) return null

  return (
    <div className="map-container">
      <GoogleMapReact
        bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY}}
        center={{lat: latitude, lng: longitude}}
        defaultZoom={16}
        fullscreenControl={false}
        options={{styles: MapStyle}}
        style={{flexGrow: 1}}
      >
        <Marker
          lat={latitude}
          lng={longitude}
          src="icons/map-marker.svg"
          alt="Map Marker"
        />
      </GoogleMapReact>
    </div>
  )
}

export default Map
