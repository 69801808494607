import React from 'react'
import copy from 'copy-to-clipboard'
import {
  Container,
  LabelContainer,
  Label,
  SecondaryLabel,
} from './InfoBox.styled'
import Icon from '../shared/Icon/Icon'
import {InstallAppButton, CopyLinkButton} from '../Controls/Controls.styled'
import {useState} from 'react'

export function CopyLinkBox() {
  const [copied, setCopied] = useState(false)
  const isNativeShareAvailable = typeof navigator?.share !== 'undefined'
  const currentURL = window.location.href

  function handleShareClick() {
    navigator.share({
      title: 'Circuit Package Tracker',
      url: currentURL,
    })
  }

  function handleCopyClick() {
    /**
     * Otherwise, just copy link to clipboard and update the button text
     */
    copy(currentURL)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  return (
    <InfoBox>
      {isNativeShareAvailable ? (
        <CopyLinkButton onClick={handleShareClick}>Share link</CopyLinkButton>
      ) : copied ? (
        <CopyLinkButton data-copied={copied} onClick={handleCopyClick}>
          <span>Link copied</span>
          <img src={`/icons/check.svg`} alt="Checkmark icon" />
        </CopyLinkButton>
      ) : (
        <CopyLinkButton onClick={handleCopyClick}>
          Copy tracking link
        </CopyLinkButton>
      )}
    </InfoBox>
  )
}

export function InstallAppBox({installURL}) {
  if (!installURL) {
    return null
  }

  return (
    <InfoBox>
      <Label style={{textAlign: 'center'}}>
        Automatically track all your orders
      </Label>
      <InstallAppButton href={installURL} target="_blank">
        Install Circuit Package Tracker app
      </InstallAppButton>
    </InfoBox>
  )
}

export function ContactBox({contactInformation}) {
  const phone = contactInformation?.phone
  const email = contactInformation?.email

  if (!email && !phone) {
    return null
  }

  return (
    <InfoBox icon="question-mark" iconAlt="Team name">
      <Label style={{marginBottom: 16}}>Need help?</Label>
      {phone && (
        <>
          <SecondaryLabel>Call us</SecondaryLabel>
          <Label style={{marginBottom: 16}}>
            <a href={`tel:${phone}`}>{phone}</a>
          </Label>
        </>
      )}
      {email && (
        <>
          <SecondaryLabel>Send an email</SecondaryLabel>
          <Label>
            <a href={`mailto:${email}`}>{email}</a>
          </Label>
        </>
      )}
    </InfoBox>
  )
}

const InfoBox = ({icon, iconAlt, label, secondaryLabel, action, children}) => {
  return (
    <Container>
      <Icon icon={icon} alt={iconAlt} />
      <LabelContainer>
        {children != null ? (
          children
        ) : (
          <>
            {label && <Label>{label}</Label>}
            {secondaryLabel && (
              <SecondaryLabel>{secondaryLabel}</SecondaryLabel>
            )}
          </>
        )}
      </LabelContainer>
    </Container>
  )
}

export default InfoBox
