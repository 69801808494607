import axios from 'axios'

const trackerURL = process.env.REACT_APP_API_HOST + '/rest/tracker'

const updateNoteURL =
  process.env.REACT_APP_API_HOST +
  '/rest/recipients/deliveries/updateRecipientNote'

export const fetchDelivery = token => {
  return axios.post(trackerURL, {
    project: process.env.REACT_APP_API_PROJECT,
    trackingToken: token,
  })
}

export const updateNote = ({token, note}) => {
  const trimmedNote = note?.trim() || ''
  return axios.post(updateNoteURL, {
    project: process.env.REACT_APP_API_PROJECT,
    trackingToken: token,
    note: trimmedNote,
  })
}
