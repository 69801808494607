import styled from 'styled-components'
import {colors, mobileMaxWidth} from '../../shared/theme'

export const Container = styled.div`
  background-color: ${colors.blue['10']};
  box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.17);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  top: 24px;
  left: 24px;
  padding: 20px 32px 24px;
  position: relative;
  width: 310px;

  @media ${mobileMaxWidth} {
    align-items: flex-start;
    border-radius: 16px 16px 0 0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    margin: -10px 0 0;
    top: 0px;
    left: 0px;
    padding-bottom: 3px;
    width: 100%;
  }
`

export const Header = styled.h1`
  color: ${colors.dark['80']};
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
`

export const InstallAppButton = styled.a`
  background-color: ${colors.blue['05']};
  border-radius: 8px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  color: ${colors.blue['50']};
  margin-top: 8px;
  padding: 12px 8px;
  display: block;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${colors.blue['20']};
  }
`

export const CopyLinkButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: solid 1px ${colors.dark['10']};
  background-color: ${colors.white};
  border-radius: 8px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  color: ${colors.blue['50']};
  padding: 12px 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${colors.blue['20']};
    border-color: ${colors.blue['20']};
  }
  &[data-copied] {
    border: solid 1px ${colors.dark['10']};
    background-color: ${colors.white};
    color: ${colors.dark['80']};
    cursor: default;
  }
`
