import styled from 'styled-components'
import {colors, mobileMaxWidth} from '../../shared/theme'

export const Container = styled.a`
  align-items: center;
  background: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1);
  color: ${colors.dark['50']};
  display: flex;
  bottom: 24px;
  font-size: 15px;
  justify-content: center;
  padding: 16px 18px;
  position: fixed;
  right: 64px;
  text-decoration: none;
  z-index: 2;

  &:hover {
    background: #ecf0f9;
  }

  img {
    margin-left: 12px;
  }

  div {
    align-items: center;
    display: flex;
  }

  @media ${mobileMaxWidth} {
    border-radius: 0;
    background: #ecf0f9;
    bottom: 0;
    padding: 16px 0;
    position: relative;
    right: 0;
    width: 100vw;
  }
`
