import moment from 'moment'
import 'moment-timezone'
import {
  getProofOfDeliveryPhotos,
  getSignaturePhoto,
  isStopPickup,
} from '@getcircuit/schema/dist'

export const calendarStrings = {
  lastDay: '[Yesterday]',
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  sameElse: 'dddd, MMMM D',
}

export function buildGreeting(stop) {
  const isPickup = isStopPickup(stop)
  const recipient = stop.recipient?.name

  const opening = recipient
    ? 'Hi ' + recipient.split(' ')[0] + ', your'
    : 'Your'

  return `${opening} order is out for ${isPickup ? 'pickup' : 'delivery'}.`
}

const locale = navigator.language || 'en-US'
const hourFormat = Intl.DateTimeFormat(locale, {
  hour: 'numeric',
}).resolvedOptions().hourCycle

/*
 * Takes a unix timestamp like 1559804872,
 * and returns time formatted like 14:00 or 2:00pm.
 */
export function formatEpoch(epoch, timezone) {
  epoch = moment.unix(epoch)
  if (timezone) {
    epoch = epoch.tz(timezone)
  }

  if (hourFormat === 'h12') {
    return epoch.format('h:mma')
  }
  return epoch.format('H:mm')
}

export function hasAttachments(deliveryInfo) {
  const photos = getProofOfDeliveryPhotos(deliveryInfo)
  const signatureUrl = getSignaturePhoto(deliveryInfo)
  return photos.length > 0 || signatureUrl != null
}
