import React from 'react'
import useKey from 'use-key-hook'
import {Container, Photo, Button} from './Lightbox.styled'

const Lightbox = ({photoUrl, onClose, isVisible}) => {
  useKey(
    (pressedKey, event) => {
      handleClose(event)
    },
    {detectKeys: [27]},
  )

  const handleClose = event => {
    event.stopPropagation()
    onClose()
  }

  return (
    <Container className={isVisible ? 'is-visible' : ''} onClick={handleClose}>
      <Button onClick={handleClose}>Close</Button>
      <Photo
        className={isVisible ? 'is-visible' : ''}
        src={photoUrl}
        alt="Full screen photo of where your parcel was left"
      />
    </Container>
  )
}

export default Lightbox
