import React from 'react'
import Moment from 'react-moment'
import 'moment-timezone'

const Time = ({epoch, timezone}) => (
  <Moment format="HH:mm" tz={timezone} unix>
    {epoch}
  </Moment>
)

export default Time
