import styled from 'styled-components'

export const Container = styled.div`
  align-items: flex-start;
  background: white;
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 8px;
  overflow: hidden;
  padding: 12px 16px 16px;
  width: 100%;
`
