import React, {useState} from 'react'
import {useDeliveryState} from '../../../hooks'
import smoothscroll from 'smoothscroll-polyfill'
import TextareaBox from '../../TextareaBox/TextareaBox'
import {updateNote} from '../../../api'
import {Container, Cancel, Submit, SubmitContainer} from './Notes.styled'
import FormErrors from '../../shared/FormErrors/FormErrors'
import {isStopPickup} from '@getcircuit/schema/dist'

smoothscroll.polyfill()

const Notes = ({token}) => {
  const stop = useDeliveryState()
  const {recipientProvidedNotes: notes} = stop?.deliveryInfo
  const [isSubmitVisible, setIsSubmitVisible] = useState(false)
  const [newNote, setNewNote] = useState()
  const [updateError, setUpdateError] = useState()
  const [hasEverFocusedOnTextarea, setHasEverFocusedOnTexarea] = useState(false)

  const innerHeight = () =>
    typeof window.visualViewport === 'undefined'
      ? window.innerHeight
      : visualViewport.height

  const scrollAfterFocus = () => {
    setTimeout(() => {
      const poweredBy = document.getElementById('powered-by')

      if (!poweredBy) return

      window.scroll({
        top: poweredBy.offsetTop - innerHeight(),
        left: 0,
        behavior: 'smooth',
      })
    }, 250)
  }

  const handleTextareaResize = () => {
    if (hasEverFocusedOnTextarea) {
      const poweredBy = document.getElementById('powered-by')

      if (!poweredBy) return

      window.scroll({
        top: poweredBy.offsetTop - innerHeight(),
        left: 0,
      })
    }
  }

  const handleFocus = () => {
    setIsSubmitVisible(true)
    setHasEverFocusedOnTexarea(true)
    scrollAfterFocus()
  }

  const handleBlur = () => {
    setIsSubmitVisible(false)
  }

  const handleSubmit = e => {
    setIsSubmitVisible(false)
    setUpdateError(false)
    updateNote({token, note: newNote}).catch(() => {
      setIsSubmitVisible(true)
      setUpdateError("Can't save changes. Please retry")
    })
  }

  const handleCancel = () => {
    setIsSubmitVisible(false)
    setUpdateError(false)
  }

  const handleNoteChange = newNote => {
    setNewNote(newNote)
  }

  const placeholder = `Add ${
    isStopPickup(stop) ? 'pickup' : 'delivery'
  } instructions`

  return (
    <Container>
      <TextareaBox
        doHideCounter={!isSubmitVisible}
        handleBlur={handleBlur}
        handleChange={handleNoteChange}
        handleFocus={handleFocus}
        handleResize={handleTextareaResize}
        hasError={!!updateError}
        icon="pencil-blue"
        iconAlt="Edit notes for the driver"
        initialValue={notes}
        maxChars={280}
        placeholder={placeholder}
      />
      {updateError && <FormErrors error={updateError} />}
      <SubmitContainer visible={isSubmitVisible}>
        <Cancel onClick={handleCancel}>Cancel</Cancel>
        <Submit onMouseDown={handleSubmit}>Save instructions</Submit>
      </SubmitContainer>
    </Container>
  )
}

export default Notes
