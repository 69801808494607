import React from 'react'
import Moment from 'react-moment'
import 'moment-timezone'
import {calendarStrings} from '../../../utils'

const Day = ({epoch, timezone}) => (
  <Moment calendar={calendarStrings} tz={timezone} unix>
    {epoch}
  </Moment>
)

export default Day
