import React from 'react'
import {
  getProofOfDeliveryPhotos,
  getSignaturePhoto,
} from '@getcircuit/schema/dist'
import {useDeliveryState, useGetDeliveryStateLocalized} from '../../../hooks'
import {Header} from '../Controls.styled'
import InfoBox from '../../InfoBox/InfoBox'
import Day from '../Day/Day'
import Time from '../Time/Time'

import PhotoBox from '../Succeeded/PhotoBox/PhotoBox'
import {hasAttachments} from '../../../utils'

const Failed = () => {
  const {deliveryInfo} = useDeliveryState()

  const {
    attemptedAt,
    timezone,
    photoUrl,
    signeeName,
    driverProvidedRecipientNotes: note,
  } = deliveryInfo

  const photos = getProofOfDeliveryPhotos(deliveryInfo)
  const signatureUrl = getSignaturePhoto(deliveryInfo)

  const label = useGetDeliveryStateLocalized()
  const showPhotos = hasAttachments(deliveryInfo)

  return (
    <>
      <Header>{label}</Header>

      {showPhotos && (
        <PhotoBox
          signatureUrl={signatureUrl}
          photoUrl={photoUrl}
          photos={photos}
          signeeName={signeeName}
        />
      )}

      {note && (
        <InfoBox icon="info" iconAlt="Signee information" label={note} />
      )}

      <InfoBox
        icon="parcel-fail"
        iconAlt="Delivery attempt day and time"
        label={<Time epoch={attemptedAt} timezone={timezone} />}
        secondaryLabel={<Day epoch={attemptedAt} timezone={timezone} />}
      />
    </>
  )
}

export default Failed
