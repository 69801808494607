import styled from 'styled-components'
import {colors} from '../../../shared/theme'

export const Greeting = styled.p`
  color: ${colors.dark['50']};
  font-size: 16px;
  line-height: 24px;
`

export const DeliveryTimes = styled.div`
  color: ${colors.dark['80']};
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 24px;
`
