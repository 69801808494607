import React from 'react'
import {Container, HTTPStatus, ErrorMessage} from './Errors.styled'

const Error = ({status = 500, message = 'An error has occurred.'}) => (
  <Container>
    <HTTPStatus>{status}</HTTPStatus>
    <ErrorMessage>{message}</ErrorMessage>
  </Container>
)

export default Error
