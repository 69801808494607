import React from 'react'
import {
  getProofOfDeliveryPhotos,
  getSignaturePhoto,
} from '@getcircuit/schema/dist'
import {useDeliveryState, useGetDeliveryStateLocalized} from '../../../hooks'
import Day from '../Day/Day'
import Time from '../Time/Time'
import {Header} from '../Controls.styled'
import InfoBox from '../../InfoBox/InfoBox'
import PhotoBox from './PhotoBox/PhotoBox'
import {hasAttachments} from '../../../utils'

const NoteBox = ({signeeName, note}) => {
  if (!(signeeName || note)) {
    return null
  }

  return (
    <InfoBox
      icon="info"
      iconAlt="Signee information"
      label={note || `Delivered to ${signeeName.split(' ')[0]}`}
    />
  )
}

const Succeeded = () => {
  const {deliveryInfo} = useDeliveryState()
  const {
    attemptedAt,
    timezone,
    driverProvidedRecipientNotes,
    photoUrl,
    signeeName,
  } = deliveryInfo

  const photos = getProofOfDeliveryPhotos(deliveryInfo)
  const signatureUrl = getSignaturePhoto(deliveryInfo)

  const label = useGetDeliveryStateLocalized()
  const showPhotos = hasAttachments(deliveryInfo)

  return (
    <>
      <Header>{label}</Header>

      {showPhotos && (
        <PhotoBox
          signatureUrl={signatureUrl}
          photoUrl={photoUrl}
          photos={photos}
          signeeName={signeeName}
        />
      )}

      <NoteBox signeeName={signeeName} note={driverProvidedRecipientNotes} />

      <InfoBox
        icon="parcel-success"
        iconAlt="Delivery day and time"
        label={<Time epoch={attemptedAt} timezone={timezone} />}
        secondaryLabel={<Day epoch={attemptedAt} timezone={timezone} />}
      />
    </>
  )
}

export default Succeeded
