import React from 'react'
import IconContainer from './Icon.styled'

const Icon = ({icon, alt}) => {
  if (!icon || !alt) return null

  return <IconContainer src={`/icons/${icon}.svg`} alt={alt} />
}

export default Icon
