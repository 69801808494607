import styled from 'styled-components'
import {colors} from '../../../shared/theme'

export const Container = styled.div`
  color: ${colors.red['50']};
  font-size: 16px;
`

export const Error = styled.p`
  margin: 0 0 5px;
  text-align: right;
`
