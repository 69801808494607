import styled from 'styled-components'
import {Container, SecondaryLabel} from '../../../InfoBox/InfoBox.styled'

export const PhotoBoxContainer = styled(Container)`
  flex-wrap: wrap;
  position: relative;
  padding-right: 0;

  div {
    display: flex;
    margin-bottom: 10px;
  }
`

export const PhotoInfoContainer = styled.div`
  align-items: center;
  display: flex;
`

export const PhotoLabel = styled(SecondaryLabel)`
  padding-right: 10px;
`

export const Photo = styled.img`
  cursor: zoom-in;
  width: 80px;
  height: 56px;
  object-fit: cover;
  border: 1px transparent;
  border-radius: 0.25rem;
`

export const Signature = styled.img`
  cursor: zoom-in;
  width: 96px;
  height: 56px;
  object-fit: cover;
  border: 1px solid;
  border-color: rgb(220, 226, 238);
  border-radius: 0.25rem;
`

export const PhotoList = styled.section`
  padding-left: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;

  &[data-stretch] {
    padding-left: 2rem;
  }
`
