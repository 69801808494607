import React from 'react'
import {useDeliveryState} from '../../hooks'
import {Container} from './Controls.styled'
import Scheduled from './Scheduled/Scheduled'
import Succeeded from './Succeeded/Succeeded'
import Failed from './Failed/Failed'
import InfoBox, {
  ContactBox,
  CopyLinkBox,
  InstallAppBox,
} from '../InfoBox/InfoBox'
import Notes from '../Controls/Notes/Notes'

const Address = () => {
  const {addressLineOne, addressLineTwo} = useDeliveryState()
  return (
    <>
      {addressLineOne}
      <br />
      {addressLineTwo}
    </>
  )
}

const InfoContainerForDeliveryStatus = () => {
  const {deliveryInfo} = useDeliveryState()
  const {succeeded, attempted} = deliveryInfo

  if (succeeded) {
    return <Succeeded />
  }

  if (!attempted) {
    return <Scheduled />
  }

  return <Failed />
}

const Controls = ({token}) => {
  const {deliveryInfo, recipient = {}, installURL, team} = useDeliveryState()
  const {succeeded, attempted} = deliveryInfo

  return (
    <Container>
      <InfoContainerForDeliveryStatus />
      {/* Only show notes if the driver didn't get to stop already  */}
      {attempted === false && <Notes token={token} />}
      {/* Contact information stays on top if the order didn't arrive yet */}
      {succeeded === false && (
        <ContactBox contactInformation={team.contactInformation} />
      )}

      <InfoBox
        icon="small-truck"
        iconAlt="Team name"
        label={team.displayName}
      />
      <InfoBox
        icon={attempted ? 'account-circle' : 'account-circle-blue'}
        iconAlt="Recipient Information"
        label={recipient?.name ? recipient.name : false}
        secondaryLabel={<Address />}
      />

      {succeeded === true && (
        <ContactBox contactInformation={team.contactInformation} />
      )}
      <CopyLinkBox />
      <InstallAppBox installURL={installURL} />
    </Container>
  )
}

export default Controls
