import React from 'react'
import {Container, Error} from './FormErrors.styled'

const parseError = error => {
  const errorResponseData = error.response?.data
  if (errorResponseData) {
    return Object.values(errorResponseData).flat()
  }
  if (error.message) {
    return [error.message]
  }
  return ['An error has occurred. Please try again.']
}

const FormErrors = ({error}) => {
  return (
    <Container>
      {parseError(error).map((error, i) => (
        <Error key={`error-${i}`}>{error}</Error>
      ))}
    </Container>
  )
}

export default FormErrors
