import React, {useState} from 'react'
import {
  PhotoBoxContainer,
  PhotoInfoContainer,
  PhotoLabel,
  Photo,
  Signature,
  PhotoList,
} from './Photobox.styled'
import Lightbox from '../../../shared/Lightbox/Lightbox'
import Icon from '../../../shared/Icon/Icon'
import {useAttachmentsDefaultMessage} from '../../../../hooks/useAttachmentsDefaultMessage'

const PhotoBox = ({
  driverProvidedRecipientNotes,
  photos,
  signatureUrl,
  signeeName,
}) => {
  const [currentPhoto, setCurrentPhoto] = useState(undefined)
  const [isLightboxVisible, setIsLightboxVisible] = useState(false)
  const defaultMessage = useAttachmentsDefaultMessage()

  if (!(photos || driverProvidedRecipientNotes)) {
    return null
  }

  const stretchImageGallery = !!photos && photos.length > 1 && signatureUrl

  return (
    <PhotoBoxContainer>
      <PhotoInfoContainer>
        <Icon icon="info" alt="Details about your delivery" />
        <PhotoLabel>
          {driverProvidedRecipientNotes
            ? driverProvidedRecipientNotes
            : defaultMessage}
        </PhotoLabel>
      </PhotoInfoContainer>
      <PhotoList data-stretch={stretchImageGallery}>
        {signatureUrl && (
          <>
            <Signature
              src={signatureUrl}
              alt={`Your delivery was signed by ${signeeName}`}
              onClick={() => {
                setCurrentPhoto(signatureUrl)
                setIsLightboxVisible(true)
              }}
            />
            <Lightbox
              photoUrl={signatureUrl}
              isVisible={isLightboxVisible && signatureUrl === currentPhoto}
              onClose={() => setIsLightboxVisible(!isLightboxVisible)}
            />
          </>
        )}
        {photos.length > 0 &&
          photos.map((photo, index) => (
            <React.Fragment key={index}>
              <Photo
                src={photo}
                alt="Your delivery was placed here"
                onClick={() => {
                  setCurrentPhoto(photo)
                  setIsLightboxVisible(true)
                }}
              />
              <Lightbox
                photoUrl={photo}
                isVisible={isLightboxVisible && photo === currentPhoto}
                onClose={() => setIsLightboxVisible(!isLightboxVisible)}
              />
            </React.Fragment>
          ))}
      </PhotoList>
    </PhotoBoxContainer>
  )
}

export default PhotoBox
