import styled from 'styled-components'
import Div100vh from 'react-div-100vh'
import {fontFamily, colors, mobileMaxWidth} from './shared/theme'

const AppContainer = styled(Div100vh)`
  * {
    font-family: ${fontFamily};
    font-weight: 500;
  }

  a {
    color: ${colors.blue['50']};
  }

  @media ${mobileMaxWidth} {
    display: flex;
    flex-direction: column;
  }
`

export default AppContainer
