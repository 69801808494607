import React from 'react'
import {useDeliveryState} from '../../../hooks'
import {Greeting, DeliveryTimes} from './Scheduled.styled'
import {buildGreeting, formatEpoch} from '../../../utils'

const TimeRange = ({earliestTime, latestTime, timezone}) => {
  if (earliestTime && latestTime) {
    const earliestHumanFormat = formatEpoch(earliestTime, timezone)
    const latestHumanFormat = formatEpoch(latestTime, timezone)

    return (
      <DeliveryTimes>
        {earliestHumanFormat + ' – ' + latestHumanFormat}
      </DeliveryTimes>
    )
  } else {
    return null
  }
}

const Scheduled = () => {
  const stop = useDeliveryState()
  const {earliestTime, latestTime, timezone} = stop?.deliveryInfo
  const greeting = buildGreeting(stop)

  return (
    <>
      <Greeting>{greeting}</Greeting>
      <TimeRange
        earliestTime={earliestTime}
        latestTime={latestTime}
        timezone={timezone}
      />
    </>
  )
}

export default Scheduled
